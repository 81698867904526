import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MandatStateEnum,
  TvaExprimeEnum,
} from '@features/mandats/domain/entities/mandat.interface';
import { SelectItem } from '@libs/select/select-options';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit, OnChanges {
  @Input({ required: true }) priceForm!: FormGroup;
  @Input({ required: false }) mandatPredefinedForm?: FormGroup;

  public tvaItems!: SelectItem[];

  private readonly readOnlyStates = [
    MandatStateEnum.active,
    MandatStateEnum.underCompromise,
    MandatStateEnum.underOffer,
    MandatStateEnum.archived,
    MandatStateEnum.duplicate,
  ];

  get isEditable(): boolean {
    console.log('--- Price Component Debug ---');
    if (!this.mandatPredefinedForm) {
      console.log('mandatPredefinedForm is not provided');
      return true;
    }

    const stateControl = this.mandatPredefinedForm.get('etat');
    if (!stateControl) {
      console.log('No etat control found in mandatPredefinedForm');
      return true;
    }

    const currentState = stateControl.value;
    console.log('Current state:', currentState);
    console.log('ReadOnly states:', this.readOnlyStates);
    const isReadOnly = this.readOnlyStates.includes(currentState);
    console.log('Is read-only:', isReadOnly);
    console.log('Is editable:', !isReadOnly);
    console.log('mandatPredefinedForm value:', this.mandatPredefinedForm.value);
    return !isReadOnly;
  }

  ngOnInit() {
    console.log('Price Component ngOnInit');
    console.log('Initial mandatPredefinedForm:', this.mandatPredefinedForm);
    console.log(
      'Initial mandatPredefinedForm value:',
      this.mandatPredefinedForm?.value
    );
    this.initializeTvaItems();
    this.setupPriceChangeSubscription();
    this.updateFormControlsState();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('Price Component ngOnChanges', changes);
    if (changes['mandatPredefinedForm']) {
      console.log(
        'mandatPredefinedForm previous:',
        changes['mandatPredefinedForm'].previousValue
      );
      console.log(
        'mandatPredefinedForm current:',
        changes['mandatPredefinedForm'].currentValue
      );
    }
  }

  private initializeTvaItems() {
    this.tvaItems = [
      {
        id: TvaExprimeEnum.exprimes_ht,
        name: 'Honoraires HT',
      },
      {
        id: TvaExprimeEnum.exprimes_ttc,
        name: 'Honoraires TTC',
      },
    ];
  }

  private setupPriceChangeSubscription() {
    this.priceForm
      .get('prix_de_presentation')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: number) => {
        const droitsMutation = this.calculateDroitDeMutation(value);
        this.priceForm.get('droits_mutation')?.setValue(droitsMutation);
      });
  }

  private updateFormControlsState() {
    if (!this.isEditable) {
      const controlsToDisable = [
        'prix_de_presentation',
        'honoraires_vendeur',
        'honoraires_acquereur',
      ];
      controlsToDisable.forEach(controlName => {
        const control = this.priceForm.get(controlName);
        if (control) {
          control.disable();
        }
      });
    }
  }

  private calculateDroitDeMutation(total: number): number {
    if (!total || total === 0) {
      return 0;
    }
    const step1 = 23000;
    const step2 = 200000;
    if (total > step1) {
      if (total <= step2) {
        return Number((0.03 * (total - step1)).toFixed(2));
      } else {
        return Number((5310 + 0.05 * (total - step2)).toFixed(2));
      }
    }
    return 25;
  }
}
