<form
  *ngIf="shouldDisplaySearchCompaniesBar"
  [formGroup]="companiesMandantForm"
  class="create-form">
  <app-btn
    (btnClick)="backupForm()"
    *ngIf="
      !companiesMandantForm.get('siren')?.value && canCancelTheChangeCompany
    "
    color="alerte"
    marginAuto="auto"
    size="large"
    text="Restaurer"></app-btn>

  <app-btn
    (btnClick)="setOnlyPhysique()"
    *ngIf="!this.isEditMode && shouldDisplaySearchCompaniesBar"
    size="large"
    text="Personne physique uniquement"></app-btn>
  <div class="form-group">
    <app-search-pappers
      (optionSelectedEvent)="getPappersResult($event)"
      id="company"></app-search-pappers>
  </div>
</form>

<div class="create-form">
  <app-btn
    (btnClick)="toggleBackToSearch()"
    *ngIf="
      !companiesMandantForm.get('siren')?.value &&
      !shouldDisplaySearchCompaniesBar
    "
    size="large"
    text="Ajouter une personne morale"></app-btn>
  <app-company-card-edit-mandat
    (editCompany)="changeCompanyFromChildCall()"
    (noCompany)="resetFormFromChildCall()"
    *ngIf="companiesMandantForm.get('siren')?.value"
    [adresseLigne1]="companiesMandantForm.get('adresse_ligne_1')?.value"
    [adresseLigne2]="companiesMandantForm.get('adresse_ligne_2')?.value"
    [codePostal]="companiesMandantForm.get('code_postal')?.value"
    [libelleCodeNaf]="companiesMandantForm.get('libelle_code_naf')?.value"
    [raisonSociale]="companiesMandantForm.get('denomination')?.value"
    [sirenFormate]="companiesMandantForm.get('siren')?.value"
    [ville]="companiesMandantForm.get('ville')?.value">
  </app-company-card-edit-mandat>
</div>
