<form [formGroup]="priceForm" class="create-form">
  <!-- Prix de présentation -->
  <ng-container *ngIf="isEditable; else readOnlyPrix">
    <app-input-slide
      [required]="true"
      formControlName="prix_de_presentation"
      inputType="price"
      placeholder="Prix de présentation"
      type="number"
      width="fit">
    </app-input-slide>
  </ng-container>
  <ng-template #readOnlyPrix>
    <div class="form-group">
      <p>Prix de présentation</p>
      <div class="grey-block">
        {{
          priceForm.get('prix_de_presentation')?.value ?? 0 | number: '1.2-2'
        }}
        €
      </div>
    </div>
  </ng-template>

  <!-- Honoraires charge vendeur -->
  <ng-container *ngIf="isEditable; else readOnlyHonorairesVendeur">
    <app-input-slide
      formControlName="honoraires_vendeur"
      inputType="price"
      placeholder="Honoraires charge vendeur"
      type="number"
      width="fit">
    </app-input-slide>
  </ng-container>
  <ng-template #readOnlyHonorairesVendeur>
    <div class="form-group">
      <p>Honoraires charge vendeur</p>
      <div class="grey-block">
        {{ priceForm.get('honoraires_vendeur')?.value ?? 0 | number: '1.2-2' }}
        €
      </div>
    </div>
  </ng-template>

  <!-- Honoraires charge acquéreur -->
  <ng-container *ngIf="isEditable; else readOnlyHonorairesAcquereur">
    <app-input-slide
      formControlName="honoraires_acquereur"
      inputType="price"
      placeholder="Honoraires charge acquéreur"
      type="number"
      width="fit">
    </app-input-slide>
  </ng-container>
  <ng-template #readOnlyHonorairesAcquereur>
    <div class="form-group">
      <p>Honoraires charge acquéreur</p>
      <div class="grey-block">
        {{
          priceForm.get('honoraires_acquereur')?.value ?? 0 | number: '1.2-2'
        }}
        €
      </div>
    </div>
  </ng-template>

  <div class="form-group">
    <label for="honoraire-tva">T.V.A sur honoraires</label>
    <app-select
      [items]="tvaItems"
      formControlName="option_tva"
      id="honoraire-tva"
      size="fit">
    </app-select>
  </div>

  <div class="form-group">
    <p>Prix net vendeur</p>
    <div class="grey-block">
      {{
        (priceForm.get('prix_de_presentation')?.value ?? 0) -
          (priceForm.get('honoraires_vendeur')?.value ?? 0) | number: '1.2-2'
      }}
      €
    </div>
  </div>

  <div class="form-group">
    <p>Droits de mutation</p>
    <div class="grey-block">
      {{ priceForm.get('droits_mutation')?.value ?? 0 | number: '1.2-2' }} €
    </div>
  </div>
</form>
